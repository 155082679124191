import React from "react";
import {Link, NavLink, Outlet, useLoaderData} from "react-router-dom";
import {getVan} from "../../api";
import {requireAuth} from "../../utils";

export async function loader({request, params}) {
    await requireAuth(request);
    return getVan(params.id);
}

export default function HostVansDetail() {
    const van = useLoaderData();

    const activeStyle = {
        fontWeight: "bold",
        textDecoration: "underline",
        color: "#161616"
    }

    return (
        <section>
            <Link
                to=".."
                relative={"path"}
                className="back-button"
            >&larr; <span>Back to all vans</span></Link>
            <div className={"host-van-single-focus-container"}>
                <div className={"host-van-single-focus"}>
                    <img src={van.imageUrl} alt={`${van.name}`}/>
                    <div className={"host-van-single-focus-info"}>
                        <i className={`van-type ${van.type} selected`}>{van.type}</i>
                        <h3>{van.name}</h3>
                        <p>${van.price}/day</p>
                    </div>
                </div>
                <nav className="host-nav-detail">
                    <NavLink to="." end style={({isActive}) => isActive ? activeStyle : null}>Detail</NavLink>
                    <NavLink to="pricing" style={({isActive}) => isActive ? activeStyle : null}>Pricing</NavLink>
                    <NavLink to="photos" style={({isActive}) => isActive ? activeStyle : null}>Photos</NavLink>
                </nav>
                <Outlet context={van}/>
            </div>
        </section>
    );
}