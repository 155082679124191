import React, {Suspense} from "react";
import {Link, defer, useLoaderData, useSearchParams, Await} from "react-router-dom";
import {getVans} from "../../api";


export function vansLoader() {
    const vansPromise = getVans();
    return defer({vans: vansPromise});
}

/**
 * Challenge: remove manual error handling code and any
 * React state code we no longer need, as well as set up
 * errorElement handling for the following routes:
 * - /vans
 * - /vans/:id
 * - /host/vans
 * - /host/vans/:id
 *
 * Remember: we created an <Error /> component awhile back
 * that you should be able to reuse.
 */

export default function Vans() {
    const [searchParams, setSearchParams] = useSearchParams();
    const typeFilter = searchParams.get("type");
    const vansData = useLoaderData()

    function renderVans(vans) {
        const displayVans = typeFilter ? vans.filter(van => van.type === typeFilter) : vans;

        const vanElements = displayVans.map(van => (
            <div key={van.id} className="van-tile">
                <Link to={van.id} state={{search: `?${searchParams.toString()}`}}>
                    <img src={van.imageUrl} alt={"van"}/>
                    <div className="van-info">
                        <h3>{van.name}</h3>
                        <p>${van.price}<span>/day</span></p>
                    </div>
                    <i className={`van-type ${van.type} selected`}>{van.type}</i>
                </Link>
            </div>
        ))

        return (
            <div>
                <div className="van-list-filter-buttons">
                    <button className={`van-type simple ${typeFilter === "simple" ? "selected" : ""}`}
                            onClick={() => setSearchParams({"type": "simple"})}>Simple
                    </button>
                    <button className={`van-type rugged ${typeFilter === "rugged" ? "selected" : ""}`}
                            onClick={() => setSearchParams({"type": "rugged"})}>Rugged
                    </button>
                    <button className={`van-type luxury ${typeFilter === "luxury" ? "selected" : ""}`}
                            onClick={() => setSearchParams({"type": "luxury"})}>Luxury
                    </button>
                    {typeFilter &&
                        <button className={"van-type clear-filters"}
                                onClick={() => setSearchParams({})}>Clear
                            Filters</button>}
                </div>

                <div className="van-list">
                    {vanElements}
                </div>
            </div>
        )
    }

    return (
        <div className="van-list-container">
            <h1>Explore our van options</h1>
            <Suspense fallback={<h2>Loading vans...</h2>}>
                <Await resolve={vansData.vans}>
                    {renderVans}
                </Await>
            </Suspense>

        </div>
    );
}

