import React, {Suspense} from "react";
import {Await, defer, Link, useLoaderData} from "react-router-dom";
import {getHostVans} from "../../api";
import {requireAuth} from "../../utils";

/* Host Van Loader*/
export async function loader({request}) {
    await requireAuth(request);
    return defer({vans: getHostVans()});
}

export default function HostVans() {
    const vansPromise = useLoaderData();

// sourcery skip: avoid-function-declarations-in-blocks
    function renderVanElements(vans) {
        const hostVansEls = vans.map(van => (
            <Link
                to={van.id}
                key={van.id}
                className="host-van-link-wrapper"
            >
                <div className="host-van-single" key={van.id}>
                    <img src={van.imageUrl} alt={`${van.name}`}/>
                    <div className="host-van-info">
                        <h3>{van.name}</h3>
                        <p>${van.price}/day</p>
                    </div>
                </div>
            </Link>
        ))

        return (
            <div className="host-vans-list">
                <section>
                    {hostVansEls}
                </section>
            </div>
        )
    }

    return (
        <section>
            <h1 className="host-vans-title">Your listed vans</h1>
            <Suspense fallback={<div>Loading...</div>}>
                <Await resolve={vansPromise.vans}>
                    {renderVanElements}
                </Await>
            </Suspense>
        </section>
    );
}