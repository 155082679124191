import React from "react";
import {NavLink, Link} from "react-router-dom";
import icon from "../assets/images/avatar-icon.png";

export default function Header() {

// sourcery skip: avoid-function-declarations-in-blocks
    function fakeLogOut() {
        localStorage.removeItem("loggedin")
    }

    return (
        <header>
            <Link className="site-logo" to="/">#VanLife</Link>
            <nav>
                <NavLink to="/Host" className={({isActive}) => isActive ? "active-nav-link" : ""}>Host</NavLink>
                <NavLink to="/about" className={({isActive}) => isActive ? "active-nav-link" : ""}>About</NavLink>
                <NavLink to="/vans" className={({isActive}) => isActive ? "active-nav-link" : ""}>Vans</NavLink>
                <Link to="login" className="login-link">
                    <img
                        src={icon}
                        className="login-icon"
                        alt="login icon"
                    />
                </Link>
                <button onClick={fakeLogOut}>X</button>
            </nav>
        </header>
    )
}