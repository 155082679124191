import { redirect } from "react-router-dom";

export async function requireAuth(request) {
    const isLoggedIn = localStorage.getItem("loggedin") || false;

    const pathname= new URL(request.url).pathname || "/host";

    console.log(`isLoggedIn: ${isLoggedIn}`)
    console.log(request.url)

    if (!isLoggedIn) {
        console.log("redirecting to login")
        return redirect(`/login?message=You must log in first.&redirectTo=${pathname}`);
    }

    return null;
}

export default function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

