import React from 'react';
import {Link, useLocation, useLoaderData} from "react-router-dom";
import {getVan} from "../../api";

/* leverage useLoaderData() to get data from loader() */
export function loader({params}) {
    return getVan(params.id);
}

/* main rendering component */
export default function VanDetails() {
    const location = useLocation();
    const van = useLoaderData();

    // leverage location data for messages
    const backPath = location.state.search;
    const backParams = new URLSearchParams(backPath);
    const backMsg = backParams.get("type") || "all";

    return (
        <div className="van-detail-container">
            <Link
                to={`..${backPath}`}
                relative={"path"}
                className="back-button"
            >&larr; <span>Back to {backMsg} vans</span></Link>
            <div className="van-detail">
                <img src={van.imageUrl} alt={"van-model"}/>
                <i className={`van-type ${van.type} selected`}>{van.type}</i>
                <h2>{van.name}</h2>
                <p className="van-price"><span>${van.price}</span>/day</p>
                <p>{van.description}</p>
                <button className="link-button">Rent this van</button>
            </div>
        </div>
    );
}