import React from "react";
import {Link} from "react-router-dom";

export default function Page404 () {
    return (
        <div className="page-404">
            <h1>Sorry, the page you were looking for was not found</h1>
            <Link className={"link-button"} to={"/"}>Return to home</Link>
        </div>
    )
}