import React from "react";
import {useRouteError} from "react-router-dom"

export default function Error() {
    const error = useRouteError();
    return (
        <>
            <h1>We got an error! </h1>
            <h2>{error.status} {error.message}</h2>
        </>);
}