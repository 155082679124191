/*
    @author sbrunosalazar
    @fileoverview This is the main entry point of the application.
    @desc This file is responsible for rendering the application to the DOM.
    @exports App
 */

import React from 'react';
import Home from "./pages/Home";
import About from "./pages/About";
import Vans from "./pages/Vans/Vans";
import VanDetails from "./pages/Vans/VanDetails";
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
import "./server";
import Layout from "./components/Layout";
import HostLayout from "./components/HostLayout";
import Dashboard from "./pages/Host/Dashboard";
import Income from "./pages/Host/Income";
import Reviews from "./pages/Host/Reviews";
import HostVans from "./pages/Host/Vans";
import HostVansDetail from "./pages/Host/VansDetail";
import HostVanPricing from "./pages/Host/HostVanPricing";
import HostVanPhotos from "./pages/Host/HostVanPhotos";
import HostVanInfo from "./pages/Host/HostVanInfo";
import Page404 from "./pages/404";
import {vansLoader} from "./pages/Vans/Vans";
import {loader as vansDetailsLoader} from "./pages/Vans/VanDetails";
import {loader as hostVansLoader} from "./pages/Host/Vans";
import {loader as hostVanDetailsLoader} from "./pages/Host/VansDetail";
import Error from "./components/Error";
import Login from "./pages/Login";
import {requireAuth} from "./utils";
import {loginLoader} from "./pages/Login";
import {action as loginAction} from "./pages/Login";


const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<Layout/>} errorElement={<Error/>}>
        <Route index element={<Home/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="login" element={<Login/>} loader={loginLoader} action={loginAction}/>
        <Route path="vans" element={<Vans/>} loader={vansLoader} errorElement={<Error />}/>
        <Route path="vans/:id" element={<VanDetails/>} loader={vansDetailsLoader} errorElement={<Error />}/>

        <Route path="host" element={<HostLayout/>}>
            <Route index element={<Dashboard/>} loader={async ({request}) => await requireAuth(request)}/>
            <Route path="income" element={<Income/>} loader={async ({request}) => await requireAuth(request)}/>
            <Route path="vans" element={<HostVans/>} loader={hostVansLoader} errorElement={<Error />}/>
            <Route path="vans/:id" element={<HostVansDetail/>} loader={hostVanDetailsLoader} errorElement={<Error />}>
                <Route index element={<HostVanInfo/>} loader={async ({request}) => await requireAuth(request)}/>
                <Route path="pricing" element={<HostVanPricing/>} loader={async ({request}) => await requireAuth(request)}/>
                <Route path="photos" element={<HostVanPhotos/>} loader={async ({request}) => await requireAuth(request)}/>
            </Route>
            <Route path="reviews" element={<Reviews/>} loader={async ({request}) => await requireAuth(request)}/>
        </Route>

        <Route path="*" element={<Page404/>}/>
    </Route>
));

function App() {
    return (
        <RouterProvider router={router}/>
    )
}

export default App;