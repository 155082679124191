import React from "react"
import {redirect, useActionData, useLoaderData, useNavigation} from "react-router-dom";
import {loginUser} from "../api";
import {Form} from "react-router-dom";

export function loginLoader({request}) {
    return new URL(request.url).searchParams.get("message")
}

export async function action({request}) {
    const formData = await request.formData()
    const email = formData.get("email")
    const password = formData.get("password")

    const pathname = new URL(request.url).searchParams.get("redirectTo")

    await delay(1500)

    // try to login and catch the error message to avoid  error page
    try {
        await loginUser({email, password})
        localStorage.setItem("loggedin", true)

        return redirect(pathname)
    } catch (err) {
        return "Uh Oh - those credentials don't work"
    }
}

const delay = (ms) => new Promise(res => setTimeout(res, ms))

export default function Login() {
    const msg = useLoaderData();
    const errorMessage = useActionData();
    const navigation = useNavigation()

    return (
        <div className="login-container">
            {msg && <h2 className="red">{msg}</h2>}
            <h1>Sign in to your account</h1>
            {errorMessage && <h2 className="red">{errorMessage}</h2>}
            <Form method="post" className="login-form" replace>
                <input name="email" type="email" placeholder="Email address"/>
                <input name="password" type="password" placeholder="Password"/>
                <button disabled={navigation.state === "submitting"}>
                    {navigation.state === "submitting" ? "Logging in..." : "Log in"}
                </button>
            </Form>
        </div>
    )

}