import React from "react";
import {useOutletContext} from "react-router-dom";
import capitalizeFirstLetter from "../../utils";

export default function HostVanInfo() {
    const van = useOutletContext();

    return (
        <div className={"host-van-detail-info-data"}>
            <p><b>Name: </b>{van.name}</p>
            <p><b>Category: </b>{capitalizeFirstLetter(van.type)}</p>
            <p><b>Description: </b>{van.description}</p>
            <p><b>Visibility: </b>Public</p>
        </div>
    )
}